/*Global Styles*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Open+Sans:wght@300;400;600&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #f5f6f8;
  color: #333;
  width: 100%;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

ul {
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}

ul.country-list {
  text-indent: 0px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #ccc;
}

button:disabled {
  opacity: 0.5;
}

/* utility classes */
.flex-row {
  display: flex;
  align-items: center;

  gap: 0.5rem;
}

iframe#webpack-dev-server-client-overlay{display:none!important}