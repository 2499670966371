body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


body::-webkit-scrollbar, body *::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
}

body::-webkit-scrollbar-thumb, body *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

body[data-theme="dark"]::-webkit-scrollbar-thumb, body[data-theme="dark"] *::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,.5);
    box-shadow: 0 0 1px rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(0,0,0,.5);
}

*::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
}